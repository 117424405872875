<template>
    <div>
        <section class="content-container" v-if="product && isContentReady">
            <div class="filter">
                <div class="box product-navigator">
                    <div class="navigator-title"><b-icon icon="sliders" class="icon"></b-icon> ยางที่ค้นหา</div>
                    <product-navigator :productData="product"></product-navigator>
                </div>
            </div>
            <div class="content-body" @copy.prevent @paste.prevent>
                <div class="product-box-single">
                    <div class="box-wrapper">
                        <img :src="product.brandLogo" :alt="'ยางรถยนต์ ' + product.brand" :title="'ยางรถยนต์ ' + product.brand" class="brand d-sm-none" @error="helper.setNoImg($event, 'brand')" />
                        <div class="main">
                            <router-link
                                :to="helper.replaceRouteParam(routerPath.TIRE_DETAIL, ':id', product.param)"
                                :title="getTitleName()"
                                class="picture picture-with-overlay">
                                <div class="out-of-stock" v-if="mode === constants.ITEM_BOX_TYPE.PURCHASE && !product.hasStock && !product.hasStockDelivery">
                                    <span>หมด</span>
                                </div>
                                <img :src="product.picture" 
                                    :title="getTitleName()"
                                    :alt="getTitleName()"
                                    @error="helper.setNoImg($event, 'product')" />
                                <div class="promotion">
                                    <div class="item" v-if="product.overlay.discount">ลด<span>{{ product.overlay.discount }}</span></div>
                                    <div class="item" v-if="product.overlay.promotion">{{ product.overlay.promotion }}</div>
                                </div>
                            </router-link>
                            <div class="benefit">
                                <div class="item discount" v-if="product.overlay.discount">ลด {{ product.overlay.discount }}</div>
                                <div class="item discount" v-if="product.overlay.promotion">{{ product.overlay.promotion }}</div>
                                <div class="item discount" v-if="product.overlay.hasTurnTire">โปรเทิร์นยาง</div>
                                <div class="item discount" v-if="product.overlay.canInstallment">ผ่อน 0%</div>
                                <div class="item free" v-if="product.overlay.hasFreeGift">มีของแถม</div>
                                <div class="item free" v-for="(item, index) in product.overlay.services" :key="index">
                                    ฟรี {{ item }}
                                </div>
                                <div class="item free" v-if="product.overlay.isAllowElecTax">ออก e-receipt ได้</div>
                                <div class="item outline" v-if="product.overlay.isPrepaid">มัดจำได้</div>
                                <div class="item outline" v-if="product.overlay.serviceDate">{{ product.overlay.serviceDate }}</div>
                                <div class="item outline" v-if="product.overlay.deliveryPeriod">{{ product.overlay.deliveryPeriod }}</div>
                            </div>
                        </div>
                        <div class="info">
                            <img :src="product.brandLogo" :alt="product.brand" :title="product.brand" class="brand d-none d-sm-block" @error="helper.setNoImg($event, 'brand')" />
                            <h2>{{ getTitleName() }} <span>ปีผลิต <i>{{ product.year }}</i></span></h2>
                            <div class="desc">
                                <div class="data">
                                    <div class="item">
                                        <label>ขนาดยาง</label>
                                        <span>{{ product.size }}</span>
                                    </div>
                                    <!-- Display Price for Store -->
                                    <template v-if="mode === constants.ITEM_BOX_TYPE.PURCHASE">
                                        <div class="item" v-if="product.price">
                                            <label>ราคาต่อเส้น<br class="d-block d-sm-none" />(ติดตั้งที่ร้าน)</label>
                                            <div class="price">
                                                <span :class="{ 'cut': product.discountPrice }">฿ {{ product.price }}</span>
                                                <span v-if="product.discountPrice">฿ {{ product.discountPrice }}</span>
                                            </div>
                                        </div>
                                        <div class="item" v-if="product.priceDelivery">
                                            <label>ราคาต่อเส้น<br class="d-block d-sm-none" />(จัดส่ง)</label>
                                            <div class="price">
                                                <span :class="{ 'cut': product.discountPriceDelivery }">฿ {{ product.priceDelivery }}</span>
                                                <span v-if="product.discountPriceDelivery">฿ {{ product.discountPriceDelivery }}</span>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- Display Price for Other -->
                                    <template v-else>
                                        <div class="item">
                                            <label>ราคาเริ่มต้นเส้นละ</label>
                                            <div class="price">
                                                <span>฿ {{ product.priceRange }}</span>
                                            </div>
                                        </div>
                                    </template>
                                </div>

                                <!-- For Store -->
                                <div class="operation" v-if="mode === constants.ITEM_BOX_TYPE.PURCHASE">
                                    <button
                                        v-if="product.hasDelivery"
                                        :title="product.size + ' ' + product.brand + ' ' + product.name"
                                        class="btn btn-warning btn-purchase"
                                        :class="{ 'not-allow': !product.hasDelivery }"
                                        @click="purchaseDelivery(product.hasDelivery)">
                                        <b-icon icon="truck" class="icon"></b-icon> สั่งซื้อ-จัดส่ง
                                    </button>
                                    <button
                                        v-if="product.hasInstallation"
                                        :title="product.size + ' ' + product.brand + ' ' + product.name"
                                        class="btn btn-red-light btn-purchase"
                                        :class="{ 'not-allow': !product.hasInstallation }"
                                        @click="purchaseInstall(product.hasInstallation)">
                                        <b-icon icon="tools" class="icon"></b-icon> สั่งซื้อ-ติดตั้ง
                                    </button>
                                    <a :href="helper.getFacebookLink()"
                                        target="_blank"
                                        class="btn btn-grey btn-purchase"
                                        v-if="!product.hasInstallation && !product.hasDelivery">
                                        <b-icon icon="messenger" class="icon"></b-icon> สอบถามรุ่นอื่นๆ
                                    </a>
                                </div>

                                <!-- For other -->
                                <div class="operation" v-else>
                                    <button
                                        class="btn btn-warning btn-purchase"
                                        :class="{ 'not-allow': !product.hasDelivery }"
                                        @click="toggleStoreList(product.hasDelivery, true)"
                                        v-if="product.hasSeller">
                                        <b-icon icon="truck" class="icon"></b-icon> ดูราคา-ร้านจัดส่ง
                                    </button>
                                    <button
                                        class="btn btn-red-light btn-purchase"
                                        :class="{ 'not-allow': !product.hasInstallation }"
                                        @click="toggleStoreList(product.hasInstallation, false)"
                                        v-if="product.hasSeller">
                                        <b-icon icon="tools" class="icon"></b-icon> ดูราคา-ร้านติดตั้ง
                                    </button>
                                    <a :href="helper.getFacebookLink()"
                                        target="_blank"
                                        class="btn btn-grey btn-purchase"
                                        v-if="!product.hasSeller">
                                        <b-icon icon="messenger" class="icon"></b-icon> สอบถามรุ่นอื่นๆ
                                    </a>
                                </div>
                                
                                <article>
                                    ความเร็วสูงสุด {{ product.maxSpeedUnit }}: {{ product.maxSpeedKm }} | กม./ชม รับน้ำหนักต่อล้อ {{ product.weightTireIndex }}: {{ product.weightTireKg }} กก.
                                    <br />
                                    <p>{{ product.shortDesc }}</p>
                                </article>

                                <div class="certificate mt-3" v-if="product.country.flag && product.country.text">
                                    <div><img :src="product.country.flag" :alt="product.country.text" :title="product.country.text" /></div>
                                    <div><img v-if="product.tsiPicture" :src="product.tsiPicture" :alt="'มอก. ' + product.name" :title="'มอก. ' + product.name" /></div>
                                    <div><img v-if="product.performance.icon" :src="product.performance.icon" :alt="'Performance ' + product.name" :title="'Performance ' + product.name" /></div>
                                </div>
                            </div>

                            <div class="content-product-attribute mt-3">
                                <h4>คุณสมบัติยางรถยนต์ <a href="#" target="_blank"><b-icon icon="info-circle-fill"></b-icon></a></h4>

                                <div class="icons">
                                    <img :src="item.icon" :alt="item.title" :title="item.title" v-for="(item, index) in product.attributes" :key="index" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content-related-items mt-5" v-if="productRelate.length > 0 && isProductRelateReady">
                    <div class="loading" v-if="!isProductRelateReady">
                        <div class="item">
                            <loader-content :isDisplay="true"></loader-content>
                        </div>
                    </div>

                    <template v-if="isProductRelateReady">
                        <div class="bar mb-2">
                            <h2><b-icon icon="box-seam" class="icon"></b-icon> ยางรถยนต์รุ่นอื่นๆ</h2>
                        </div>
                        <product-related :products="productRelate" column="column-5" class="d-none d-lg-block"></product-related>
                        <product-related :products="productRelate" column="column-3" class="d-none d-md-block d-lg-none"></product-related>
                        <product-related :products="productRelate" column="column-2" class="d-sm-block d-md-none"></product-related>
                    </template>
                </div>
            </div>
        </section>

        <div class="no-data" v-if="!product && isContentReady">
            <b-icon icon="box-seam"></b-icon>
            <span>ไม่พบรายการสินค้าที่คุณกำลังค้นหาในร้านนี้</span>
        </div>

        <modal-store-list
            @close="closeStoreList()"
            :isDisplay="isOpenStoreList"
            :isDelivery="isDelivery"
            :initFilters="initStoreFilter"
            :product="selectedProduct"
            :trackingReferrer="trackingReferrer"
            :networkId="networkId">
        </modal-store-list>
    </div>
</template>

<script>
import RouterPath from '@/router/path';
import MixinModalStoreList from '@/mixins/modalStoreList';
import Helper from '@/services/helper';
import Constants from '@/variables/constants';
import OrderService from '@/services/orderService';
import ProductService from '@/services/productService';
import GTMService from '@/services/gtmService';
import ProductNavigator from '@/components/product/ProductNavigator';
import ProductRelated from '@/components/product/ProductRelated';
import ModalStoreList from '@/components/store/ModalStoreList';


export default {
    mixins: [ MixinModalStoreList ],
    components: {
        ProductNavigator,
        ProductRelated,
        ModalStoreList
    },
    data() {
        return {
            routerPath: RouterPath,
            helper: Helper,
            constants: Constants,
            isOpenStoreList: false,
            isDelivery: false,
            isContentReady: false,
            isProductRelateReady: false,
            productRelate: []
        };
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        mode: {
            type: String,
            required: true
        },
        store: {
            type: Object,
            required: false
        },
        networkId: {
            type: String,
            required: false,
        },
        trackingReferrer: {
            type: String,
            required: false
        },
        initStoreFilter: {
            type: Array,
            requred: false
        },
        toggleModalStore: {
            type: Object,
            require: false
        }
    },
    mounted() {
        this.isContentReady = true;
        this.getRelatedProduct();
        GTMService.gtmViewProduct(this.$gtm, this.product);

        // Check Toggle Modal Store List
        this.checkAutoModalStore();
    },
    methods: {
        getTitleName() {
            return [ 'ยางรถยนต์', this.product.brand, 'รุ่น', this.product.name, this.product.size ].join(' ');
        },
        checkAutoModalStore() {
            if (this.toggleModalStore) {
                if (this.toggleModalStore.isShowInstall && this.product.hasInstallation) {
                    this.openStoreList({ product: this.product, isDelivery: false });
                } else if (this.toggleModalStore.isShowDelivery && this.product.hasDelivery) {
                    this.openStoreList({ product: this.product, isDelivery: true });
                }
            }
            
        },
        async purchaseInstall() {
            const vueObject = {
                modalLoader: this.$modalLoader,
                router: this.$router,
                gtag: this.$gtag,
                gtm: this.$gtm,
                root: this.$root,
                swal: this.$swal
            };
            const param = {
                sellerProductId: this.product.sellerProductId,
                storeId: Helper.splitDotParam(this.store.param),
                orderType: Constants.ORDER_TYPE.SERVICE,
                referer: this.trackingReferrer
            };
            const gtmData = {
                    product: this.product,
                    type: 'install',
                    sellerProductId: this.product.sellerProductId,
                    storeId: Helper.splitDotParam(this.store.param),
                    price: this.product.price
                };

            OrderService.toggleAddCartEvent(vueObject, param, gtmData);
        },
        purchaseDelivery(isAllowAction) {
            if (isAllowAction) {
                this.$emit('purchaseDelivery', this.product);
            }
        },
        toggleStoreList(isAllowAction, isDelivery) {
            if (isAllowAction) {
                this.openStoreList({ product: this.product, isDelivery: isDelivery });
            }
        },
        async getRelatedProduct() {
            this.isProductRelateReady = false;
            const result = await ProductService.getRelatedProduct(this.product.id, this.networkId, this.product.sellerProductId);

			if (result?.data) {
                this.productRelate = result.data.resultList;
			}

            this.isProductRelateReady = true;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';
@import '@/components/product/style/ProductDetail.scss';

.product-navigator {
    @media only screen and (max-width: $screenExtraLarge) {
        display: none;
    }

    .navigator-title {
        margin: 0;
        font-family: 'Open Sans', 'Kanit';
        font-size: 16px;
        font-weight: bold;

        .icon {
            margin-right: 5px;
        }
    }
}

.product-box-single {
    margin: 30px 0 20px;
    padding: 20px;
    background: #fff;
    @include borderRadius(7px);
    @include boxShadow(0 0 10px rgba(0, 0, 0, 0.2));

    @media only screen and (max-width: $screenExtraSmall) {
        padding: 12px;
    }

    .box-wrapper {
        display: flex;
        align-items: flex-start;

        @media only screen and (max-width: $screenExtraSmall) {
            display: block;
        }
    }

    .brand {
        display: block;
        width: 100%;
        max-width: 130px;
        border: 1px solid #ccc;

        @media only screen and (max-width: $screenExtraSmall) {
            margin: 0 auto 10px;
        }
    }

    .main {
        width: 290px;
        margin-right: 15px;

        @media only screen and (max-width: $screenSmall) {
            width: 40%;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            width: 100%;
        }

        .picture {
            position: relative;
            display: flex;
            align-items: center;
            align-content: center;
            width: 100%;
            border: 1px solid #ddd;
            overflow: hidden;
            @include borderRadius(20px);

            @media only screen and (max-width: $screenExtraSmall) {
                padding-top: 50%;
                margin: 0 auto 15px;
            }

            img {
                width: 100%;
                display: block;
                cursor: pointer;

                @media only screen and (max-width: $screenExtraSmall) {
                    position: absolute;
                    top: -10%;
                    left: 0;
                }
            }

            .out-of-stock {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    display: flex;
                    width: 100px;
                    height: 100px;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    background: rgba(250, 0, 0, 0.3);
                    @include borderRadius(1000px);
                }
            }

            .promotion {
                position: absolute;
                top: 5px;
                right: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                @media only screen and (max-width: $screenExtraSmall) {
                    top: unset;
                    bottom: 5px;
                    right: 0;
                }

                .item {
                    background: #ffff00;
                    color: #cc0000;
                    font-size: 13px;
                    line-height: 13px;
                    padding: 3px 5px;
                    text-align: center;
                    margin-top: 3px;

                    @media only screen and (max-width: $screenExtraSmall) {
                        font-size: 11px;
                        line-height: 11px;
                    }

                    span {
                        display: block;
                    }
                }
            }
        }

        .benefit {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;

            .item {
                font-size: 11px;
                padding: 2px 6px;
                margin: 2px;
                color: #000;
                background: #ccc;

                &.discount {
                    color: #cc0000;
                    background: #ffff00;
                }

                &.free {
                    color: #19614e;
                    background: #d8f3ec;
                }

                &.outline {
                    color: #cc0000;
                    background: none;
                    border: 1px solid #cc0000;
                }
            }
        }
    }

    .info {
        position: relative;
        flex: 1;

        h2 {
            margin: 10px 0;
            font-family: 'Open Sans', 'Kanit';
            font-weight: bold;
            font-size: 22px;
            color: #333;

            @media only screen and (max-width: $screenMedium) {
                font-size: 18px;
            }

            @media only screen and (max-width: $screenExtraSmall) {
                font-size: 16px;
                padding-right: 30px;
            }

            * {
                font-family: 'Open Sans', 'Kanit';
            }

            span {
                opacity: 0.7;
                 margin-top: 5px;
                font-size: 18px;
                font-weight: normal;
                display: block;

                @media only screen and (max-width: $screenMedium) {
                    font-size: 15px;
                }

                i {
                    font-weight: bold;
                }
            }
        }

        .desc {
            border-top: 1px solid #ddd;
            margin-top: 15px;
            padding-top: 20px;

            @media only screen and (max-width: $screenMedium) {
                padding-top: 0;
            }

            @media only screen and (max-width: $screenExtraSmall) {
                margin-top: 10px;
            }

            .data {
                display: flex;

                @media only screen and (max-width: $screenMedium) {
                    display: block;
                }

                @media only screen and (max-width: $screenExtraSmall) {
                    display: flex;
                    justify-content: space-between;
                }

                .item {
                    margin-right: 50px;

                    @media only screen and (max-width: $screenMedium) {
                        margin: 10px 0 0 0;
                    }

                    label {
                        display: block;
                        font-size: 13px;
                        line-height: 15px;
                        margin: 0;
                        color: #333;

                        @media only screen and (max-width: $screenExtraSmall) {
                            margin-bottom: 8px;
                        }
                    }

                    span {
                        display: block;
                        font-size: 26px;
                        line-height: 26px;
                        font-weight: 700;
                        color: #9b8f83;

                        @media only screen and (max-width: $screenMedium) {
                            font-size: 18px;
                            line-height: 20px;
                        }

                        @media only screen and (max-width: $screenExtraSmall) {
                            display: block;
                            font-size: 16px;
                            line-height: 18px;
                        }

                        &.price {
                            color: #bf3c3c;
                            font-family: Verdana;
                            font-weight: bold;

                            @media only screen and (max-width: $screenMedium) {
                                letter-spacing: 0;
                            }
                        }
                    }

                    .price {
                        span {
                            color: #bf3c3c;
                            font-family: Verdana;
                            font-weight: bold;

                            @media only screen and (max-width: $screenMedium) {
                                letter-spacing: 0;
                            }

                            &.cut {
                                letter-spacing: 0;
                                font-weight: normal;
                                font-size: 13px;
                                line-height: 15px;
                                color: #aaa;
                                text-decoration: line-through;
                                margin-right: 7px;

                                @media only screen and (max-width: $screenMedium) {
                                    font-size: 11px;
                                    line-height: 13px;
                                }

                                @media only screen and (max-width: $screenExtraSmall) {
                                    font-size: 10px;
                                    line-height: 12px;
                                }
                            }
                        }

                        .promotion {
                            display: block;
                            padding: 2px 5px;
                            font-size: 11px;
                            background: #fbe5d6;
                            color: #cc0000;
                            margin-left: 5px;
                        }
                    }
                }
            }

            .operation {
                margin: 20px 0;
                background: #fff;

                @media only screen and (max-width: $screenExtraSmall) {
                    position: fixed;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: 0;
                    z-index: 1;
                    display: flex;
                    @include boxShadow(0 0 15px rgba(100, 100, 100, 1));
                }

                .btn-purchase {
                    font-size: 18px;
                    padding: 10px 16px;
                    margin-right: 12px;

                    &.not-allow {
                        cursor: not-allowed;
                        background: #ccc;
                        border: 1px solid #ccc;
                        color: #666;

                        &:hover {
                            opacity: 1;
                        }
                    }
                    
                    .icon {
                        margin-right: 10px;
                    }

                    @media only screen and (max-width: $screenMedium) {
                        font-size: 16px;
                        padding: 8px 12px;
                    }

                    @media only screen and (max-width: $screenSmall) {
                        font-size: 14px;
                        padding: 8px 10px;
                        margin-right: 7px;
                    }

                    @media only screen and (max-width: $screenExtraSmall) {
                        flex-basis: 100%;
                        margin: 0;
                        padding: 12px 10px;
                        @include borderRadius(0);

                        &:nth-child(2):first-child {
                            flex-basis: 50%;
                        }
                    }

                    & + .btn-purchase {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }

            article {
                margin-top: 15px;
                padding-top: 15px;
                font-size: 13px;
                line-height: 18px;

                @media only screen and (max-width: $screenExtraSmall) {
                    border-top: 1px solid #333;
                }

                p {
                    padding-top: 15px;
                    margin: 15px 0 0;
                    border-top: 1px dashed #ddd;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .certificate {
                display: flex;
                align-items: center;

                img {
                    height: 50px;
                }

                .country {
                    height: 50px;
                }
            }
        }
    }

    .attributes {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;

        img {
            display: block;
            height: 80px;
            margin: 3px 3px 0 0;
            border: 1px solid #999;
        }
    }
}
</style>