<template>
    <section class="navigator">
        <div class="title">ยี่ห้อยาง</div>
        <div class="checkbox-list">
            <div class="item">
                <checkbox
                    v-model="isCheck"
                    :readonly="true">
                    ยาง {{ productData.brand }}
                </checkbox>
            </div>
        </div>

        <div class="title">รุ่นยาง</div>
        <div class="checkbox-list">
            <div class="item">
                <checkbox
                    v-model="isCheck"
                    :readonly="true">
                    รุ่น {{ productData.name }}
                </checkbox>
            </div>
        </div>

        <div class="title">ขนาดยาง</div>
        <div class="checkbox-list">
            <div class="item">
                <checkbox
                    v-model="isCheck"
                    :readonly="true">
                    {{ productData.size }}
                </checkbox>
            </div>
        </div>

        <div class="title">ประเภทรถยนต์</div>
        <div class="checkbox-list">
            <div class="item">
                <checkbox
                    v-model="isCheck"
                    :readonly="true">
                    {{ productData.carType }}
                </checkbox>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            isCheck: true
        };
    },
    props: {
        productData: Object
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.navigator {
    padding: 10px;
    margin-top: 10px;
    background: #fff;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    @include borderRadius(10px);

    @media only screen and (max-width: $screenExtraLarge) {
        padding: 0;
        padding-bottom: 10px;
        border-right: none;
        border-bottom: 1px dashed #ccc;
        @include borderRadius(0);
    }

    .title {
        font-size: 13px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .checkbox-list {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 10px;
        }

        .item {
            font-size: 13px;
            margin-bottom: 2px;

            @media only screen and (max-width: $screenExtraLarge) {
                font-size: 12px;
            }
        }
    }
}

::v-deep .checkbox-list {
    .item {
        img {
            height: 28px;
        }
    }
}

::v-deep .checkbox-control {
    .checkbox-button {
        width: 16px;
        height: 16px;
    }

    .checkbox-label {
        margin-left: 6px;
    }
}
</style>