<template>      
    <div class="related">
        <div class="products">
            <div class="item" :class="column" v-for="(item, index) in displayedProducts" :key="index">
                <product-box
                    :product="item"
                    :type="constants.ITEM_BOX_TYPE.GENERAL"
                    trackingReferrer="reletedProduct">
                </product-box>   
            </div>
        </div>

        <div class="load-more" v-if="checkMaxPage()">
            <button class="btn btn-danger btn-sm mb-4" @click="loadMore()">ดูเพิ่มเติม</button>
        </div>
    </div>
</template>

<script>
import ProductBox from '@/components/product/ProductBox';
import Constants from '@/variables/constants';

export default {
    components: {
        ProductBox
    },
    data() {
        return {
            constants: Constants,
            activePage: 1,
            itemsPerPage: this.getItemPerPage()
        }
    },
    props: {
        products: Array,
        column: String
    },
    computed: {
        displayedProducts() {
            const start = 0;
            const end = start + (this.activePage * this.itemsPerPage);
            
            return this.products.slice(start, end);
        },
  },
    methods: {
        getItemPerPage() {
            let items = 0

            switch (this.column) {
                case 'column-5':
                    items = 5;
                    break;

                case 'column-3':
                    items = 3;
                    break;

                case 'column-2':
                    items = 4;
                    break;
            }

            return items;
        },
        calculateMaxPage() {
            return Math.ceil(this.products.length / this.itemsPerPage) - 1;
        },
        checkMaxPage() {
            const maxPage = this.calculateMaxPage();

            if (this.activePage < maxPage) {
                return true;
            }

            return false;
        },
        loadMore() {
            const maxPage = this.calculateMaxPage();

            if (this.activePage < maxPage) {
                this.activePage++;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.related {
    .products {
        @include gridContentWrapper(0 -2px);

        .item {
            display: block;
            padding: 0 2px 4px;
            
            &.column-5 {
                width: 20%;
            }

            &.column-3 {
                width: 33.33333%;
            }

            &.column-2 {
                width: 50%;
            }
        }
    }

    .load-more {
        margin-top: 10px;
        text-align: center;

        .btn {
            font-size: 13px;
            padding: 5px 12px;
        }
    }
}
</style>